<template>
  <div class="detail">
    <div>
      <h3 class="ui-title" style="margin-left: 100px;">积分商城</h3>
      <a-form style="margin-top: 30px;" ref="form" :model="modalRef" scrollToFirstError name="form" :labelCol="{span: 6, xxl: 5}"
              :wrapperCol="{span: 14, xxl: 13 }">
        <a-form-item label="积分专场banner" :rules="[{required: true, message: '必填项不允许为空'}]" extra="建议图片比例：690 : 260">
          <a-upload
              v-model:file-list="specialList"
              list-type="picture-card"
              action="/admin/ajaxUpload.do"
              accept="image/*"
              @change="handleSpecialChange"
          >
            <div v-if="specialList.length < 1">
              <Icon icon="PlusOutlined"></Icon>
              <div style="margin-top: 8px">Upload</div>
            </div>
          </a-upload>
        </a-form-item>

        <a-form-item label="每日签到" :rules="[{required: true, message: '必填项不允许为空'}]" extra="每日签到、积分转盘、积分活动、积分商城不开启则不展示">
          <a-switch v-model:checked="modalRef.pointsShop.daySignStatus" />
        </a-form-item>

        <a-form-item label="积分转盘" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-switch v-model:checked="modalRef.pointsShop.gameStatus" />
        </a-form-item>

        <a-form-item label="积分活动" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-switch v-model:checked="modalRef.pointsShop.activityStatus" />
        </a-form-item>

        <a-form-item label="积分活动地址" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-input v-model:value="modalRef.pointsShop.activityUrl" placeholder="请输入积分活动地址"></a-input>
        </a-form-item>

        <a-form-item label="积分商城" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-switch v-model:checked="modalRef.pointsShop.shopStatus" />
        </a-form-item>

        <a-form-item label="新品上架" :rules="[{required: true, message: '必填项不允许为空'}]">
          <selectGoods v-model:value="newGoodsIdList" :selectItmes="newGoodsList" @change="(dataList) => {newGoodsList = dataList}"></selectGoods>
          <div style="margin-top: 10px; width: 700px;" v-if="newGoodsList.length">
            <a-table :pagination="false" :columns="goodsColumns" :dataSource="newGoodsList" rowKey="id"
                     :scroll="{ x: 500 }">
              <template #bodyCell="{ column, record, index }">
                <template v-if="column.key === 'action'">
                  <a-button type="link" @click="onNewGoodsDelete(record, index)">删除</a-button>
                </template>
              </template>
            </a-table>
          </div>
        </a-form-item>

        <a-form-item label="热门推荐" :rules="[{required: true, message: '必填项不允许为空'}]">
          <selectGoods v-model:value="hotIdList" :selectItems="hotList" @change="(dataList) => {hotList = dataList}"></selectGoods>
          <div style="margin-top: 10px; width: 700px;" v-if="hotList.length">
            <a-table :pagination="false" :columns="goodsColumns" :dataSource="hotList" rowKey="id"
                     :scroll="{ x: 500 }">
              <template #bodyCell="{ column, record, index }">
                <template v-if="column.key === 'action'">
                  <a-button type="link" @click="onHotDelete(record, index)">删除</a-button>
                </template>
              </template>
            </a-table>
          </div>
        </a-form-item>

        <a-form-item label="推荐商品标签" :rules="[{required: true, message: '必填项不允许为空'}]">
          <selectLabel v-model:value="labelIdList" :selectItems="labelList" @change="(dataList) => {labelList = dataList}"></selectLabel>
          <div style="margin-top: 10px; width: 700px;" v-if="labelList.length">
            <a-table :pagination="false" :columns="labelColumns" :dataSource="labelList" rowKey="id"
                     :scroll="{ x: 500 }">
              <template #bodyCell="{ column, record, index }">
                <template v-if="column.key === 'isDisabled'">
                  {{ record.isDisabled ? '已禁用' : '已启用' }}
                </template>
                <template v-if="column.key === 'action'">
                  <a-button type="link" @click="onLabelDelete(record, index)">删除</a-button>
                </template>
              </template>
            </a-table>
          </div>
        </a-form-item>

        <h3 class="ui-title" style="margin-left: 100px;">积分个人中心</h3>
        <a-form-item label="积分商城banner" :rules="[{required: true, message: '必填项不允许为空'}]" extra="建议图片比例：690 : 150">
          <a-upload
              v-model:file-list="mallList"
              list-type="picture-card"
              action="/admin/ajaxUpload.do"
              accept="image/*"
              @change="handleMallChange"
          >
            <div v-if="mallList.length < 1">
              <Icon icon="PlusOutlined"></Icon>
              <div style="margin-top: 8px">Upload</div>
            </div>
          </a-upload>
        </a-form-item>

        <a-form-item label="影迷专区" :rules="[{required: true, message: '必填项不允许为空'}]">
          <selectLabel v-model:value="filmFansIdList" :selectItems="filmFansList" @change="(dataList) => {filmFansList = dataList}"></selectLabel>
          <div style="margin-top: 10px; width: 700px;" v-if="filmFansList.length">
            <a-table :pagination="false" :columns="labelColumns" :dataSource="filmFansList" rowKey="id"
                     :scroll="{ x: 500 }">
              <template #bodyCell="{ column, record, index }">
                <template v-if="column.key === 'isDisabled'">
                  {{ record.isDisabled ? '已禁用' : '已启用' }}
                </template>
                <template v-if="column.key === 'action'">
                  <a-button type="link" @click="onFilmFansDelete(record, index)">删除</a-button>
                </template>
              </template>
            </a-table>
          </div>
        </a-form-item>

        <a-form-item label="大家都在兑" :rules="[{required: true, message: '必填项不允许为空'}]">
          <selectGoods v-model:value="exchangeIdList" :selectItems="exchangeList" @change="(dataList) => {exchangeList = dataList}"></selectGoods>
          <div style="margin-top: 10px; width: 700px;" v-if="exchangeList.length">
            <a-table :pagination="false" :columns="goodsColumns" :dataSource="exchangeList" rowKey="id"
                     :scroll="{ x: 500 }">
              <template #bodyCell="{ column, record, index }">
                <template v-if="column.key === 'action'">
                  <a-button type="link" @click="onExchangeDelete(record, index)">删除</a-button>
                </template>
              </template>
            </a-table>
          </div>
        </a-form-item>

        <a-row>
          <a-col :span="24" style="margin-top: 20px;text-align: center;">
            <a-button v-permission="['mall_activity_integral_submit']" type="primary" html-type="submit" @click="onSubmit">提交</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
  </div>
</template>

<script>
import {
  Icon
} from '@/components/icon/icon.js';
import selectLabel from "@/components/select/selectLabel/index.vue";
import selectGoods from "@/components/select/selectGoods/index.vue";
import {getPointsShopSettingDetail, updatePointsShopSetting} from "../../../../service/modules/mall";
export default {
  name: "evaluateDetail",
  components: {
    Icon,
    selectLabel,
    selectGoods
  },
  data() {
    return {
      loading: false,
      modalRef: {
        pointsShop: {},
        pointsPerson: {},
      },
      specialList: [],
      mallList: [],
      filmFansIdList: [],
      filmFansList: [],
      exchangeIdList: [],
      exchangeList: [],
      newGoodsIdList: [],
      newGoodsList: [],
      hotIdList: [],
      hotList: [],
      labelIdList: [],
      labelList: [],
      labelColumns: [{
        title: '标签名称',
        dataIndex: 'title'
      }, {
        title: '标签描述',
        dataIndex: 'description'
      }, {
        title: '状态',
        key: 'isDisabled'
      }, {
        title: '操作',
        key: 'action'
      }],
      goodsColumns: [{
        title: '商品名称',
        dataIndex: 'mainTitle'
      }, {
        title: '商品编码',
        dataIndex: 'id'
      }, {
        title: '商品分类',
        dataIndex: 'classifyName'
      }, {
        title: '操作',
        key: 'action'
      }],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    onNewGoodsDelete(item, index) {
      let i = this.newGoodsIdList.indexOf(item.id);
      this.newGoodsIdList.splice(i, 1);
      this.newGoodsList.splice(index, 1);
    },
    onHotDelete(item, index) {
      let i = this.hotIdList.indexOf(item.id);
      this.hotIdList.splice(i, 1);
      this.hotList.splice(index, 1);
    },
    onLabelDelete(item, index) {
      let i = this.labelIdList.indexOf(item.id);
      this.labelIdList.splice(i, 1);
      this.labelList.splice(index, 1);
    },
    onFilmFansDelete(item, index) {
      let i = this.filmFansIdList.indexOf(item.id);
      this.filmFansIdList.splice(i, 1);
      this.filmFansList.splice(index, 1);
    },
    onExchangeDelete(item, index) {
      let i = this.exchangeIdList.indexOf(item.id);
      this.exchangeIdList.splice(i, 1);
      this.exchangeList.splice(index, 1);
    },
    handleSpecialChange(info) {
      if (info.file.status === 'done') {
        this.specialList = info.fileList;
        const $fileList = [...this.specialList];
        this.specialList = $fileList.map(file => {
          if (file.response) {
            file.url = file.response.data.imgUrl;
          }
          return file;
        });
      }
    },
    handleMallChange(info) {
      if (info.file.status === 'done') {
        this.mallList = info.fileList;
        const $fileList = [...this.mallList];
        this.mallList = $fileList.map(file => {
          if (file.response) {
            file.url = file.response.data.imgUrl;
          }
          return file;
        });
      }
    },
    async getData() {
      this.loading = true;
      try {
        let ret = await getPointsShopSettingDetail({});
        this.loading = false;
        if(ret.code === 200) {
          ret.data.pointsShop.daySignStatus = ret.data.pointsShop.daySignStatus ? true : false;
          ret.data.pointsShop.gameStatus = ret.data.pointsShop.gameStatus ? true : false;
          ret.data.pointsShop.activityStatus = ret.data.pointsShop.activityStatus ? true : false;
          ret.data.pointsShop.shopStatus = ret.data.pointsShop.shopStatus ? true : false;

          this.newGoodsIdList = ret.data.pointsShop.newGoodsIdList.map(item => item + '');
          this.hotIdList = ret.data.pointsShop.hotGoodsIdList.map(item => item + '');
          this.labelIdList = ret.data.pointsShop.recommendGoodsLabelIdList.map(item => item + '');
          this.filmFansIdList = ret.data.pointsPerson.fansGoodsLabelIdList.map(item => item + '');
          this.exchangeIdList = ret.data.pointsPerson.allExchangeGoodsIdList.map(item => item + '');

          this.newGoodsList = ret.data.pointsShop.newGoodsList;
          this.hotList = ret.data.pointsShop.hotGoodsList;
          this.labelList = ret.data.pointsShop.recommendGoodsLabelList;
          this.filmFansList = ret.data.pointsPerson.fansGoodsLabelList;
          this.exchangeList = ret.data.pointsPerson.allExchangeGoodsList;
          delete ret.data.pointsShop.newGoodsList;
          delete ret.data.pointsShop.hotGoodsList;
          delete ret.data.pointsShop.recommendGoodsLabelList;
          delete ret.data.pointsPerson.fansGoodsLabelList;
          delete ret.data.pointsPerson.allExchangeGoodsList;

          this.modalRef = ret.data;
        }
        if(ret.data.pointsShop.saleBannerImgUrl){
          this.specialList = ret.data.pointsShop.saleBannerImgUrl.split(',').map(item=>{
            return {
              url: item,
            }
          })
        }
        if(ret.data.pointsPerson.shopBannerImgUrl){
          this.mallList = ret.data.pointsPerson.shopBannerImgUrl.split(',').map(item=>{
            return {
              url: item,
            }
          })
        }
      } catch(e) {
        this.loading = false;
      }
    },
    async onSubmit() {
      let postData = JSON.parse(JSON.stringify(this.modalRef));
      postData.pointsShop.daySignStatus = postData.pointsShop.daySignStatus ? 1 : 0;
      postData.pointsShop.gameStatus = postData.pointsShop.gameStatus ? 1 : 0;
      postData.pointsShop.activityStatus = postData.pointsShop.activityStatus ? 1 : 0;
      postData.pointsShop.shopStatus = postData.pointsShop.shopStatus ? 1 : 0;

      // 图片
      const mallList = [];
      this.mallList.forEach(item=>{
        mallList.push(item.url);
      });
      postData.pointsPerson.shopBannerImgUrl = mallList.join(',');
      const specialList = [];
      this.specialList.forEach(item=>{
        specialList.push(item.url);
      });
      postData.pointsShop.saleBannerImgUrl = specialList.join(',');

      // 列表
      postData.pointsShop.newGoodsIdList = this.newGoodsIdList;
      postData.pointsShop.hotGoodsIdList = this.hotIdList;
      postData.pointsShop.recommendGoodsLabelIdList = this.labelIdList;
      postData.pointsPerson.fansGoodsLabelIdList = this.filmFansIdList;
      postData.pointsPerson.allExchangeGoodsIdList = this.exchangeIdList;

      this.loading = true;
      try {
        let ret = await updatePointsShopSetting(postData);
        this.loading = false;
        if(ret.code === 200) {
          this.$message.success('操作成功');
          this.getData();
        }
      } catch(e) {
        this.loading = false;
      }
    },
  }
};
</script>

<style lang="less" scoped>
.detail {
  .ui-title {
    position: relative;
    margin: 20px 0 10px;
    padding-left: 10px;
    font-size: 16px;
    font-weight: 600;
  }

  .ui-title::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 4px;
    height: 20px;
    border-radius: 2px;
    background-color: #1890ff;
    transform: translateY(-50%);
  }
}
.ui-form__item {
  margin-right: 30px;
}
</style>